import createModel from "@/__main__/data-model.mjs";

const model = {
  data: {
    bungieApiUnavailable: Boolean,
  },
};

const transformedModel = model.data;

const BungieApiUnavailableModel = createModel(model).transform(
  transformedModel,
  (data) => {
    return data?.data;
  },
);

export default BungieApiUnavailableModel;
